import { formatDate, primeiroDiaMes } from './functions';

export class ContabilidadeModel {
  constructor() {
    this.id = null;
    this.nome = '';
    this.cnpj = '';
    this.cpf = '';
    this.cep = '';
    this.logradouro = '';
    this.numero = '';
    this.complemento = '';
    this.bairro = '';
    this.fone = '';
    this.celular = '';
    this.email = '';
    this.site = '';
    this.inativo = false;
    this.idMunicipio = null;
  }
}

export class ClienteModel {
  constructor() {
    this.id = null;
    this.razaoSocial = '';
    this.nomeGestor = '';
    this.cnpj = '';
    this.cep = '';
    this.logradouro = '';
    this.numero = '';
    this.complemento = '';
    this.bairro = '';
    this.fone = '';
    this.celular = '';
    this.email = '';
    this.site = '';
    this.inativo = false;
    this.idMunicipio = 0;
    this.idContabilidade = null;
    this.contabilidade = {
      id: 0,
      nome: '',
    };
    this.municipio = {
      id: 0,
      nome: '',
    };
  }
}

export class DocumentoTipoModel {
  constructor() {
    this.id = null;
    this.idDocumentoTipo = null;
    this.sinteticoAnalitico = 'A';
    this.nome = '';
    this.extensaoArquivo = '';
    this.documentoTipo = null;
    this.documentoTipos = [];
  }
}

export class UsuarioModel {
  constructor() {
    this.id = null;
    this.idContabilidade = null;
    this.email = '';
    this.nome = '';
    this.senhaHash = '';
    this.dataCadastro = new Date();
    this.inativo = false;
    this.tipoUsuario = null;
    this.usuarioClientes = [];
  }
}

export class ClienteDocumentoModel {
  constructor() {
    this.id = null;
    this.idContabilidade = null;
    this.idCliente = null;
    this.idDocumentoTipo = null;
    this.dataLancamento = new Date();
    const mesRef = primeiroDiaMes(new Date());
    this.mesReferencia = new Date(`${formatDate(mesRef, 'yyyy-MM')}-01 13:00`);
    this.documento = '';
    this.descricao = '';
    this.fileUri = '';
  }
}
