/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-extend-native */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import './config/ReactotronConfig';
import history from './services/history';
import Routes from './routes';
import GlobalStyle from './styles/global';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import './pages/_layouts/Default/css/prime-react.scss';

import { store, persistor } from './store';
import MessageDialog from './components/MessageDialog';
import MessageDialogLogin from './components/MessageDialogLogin';
import MessageDialogLoginCliente from './components/MessageDialogLoginCliente';

function App() {
  // Configura o Date para retornar o Json conside
  // Date.prototype.toJSON = function() {
  //   // console.log(this.toISOString());

  //   // const hoursDiff = this.getHours() - this.getTimezoneOffset() / 60;
  //   // this.setHours(hoursDiff);
  //   // console.log(this.toISOString());
  //   console.log(this.toDateString());
  //   return this.toDateString();
  // };

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <Routes />
          <GlobalStyle />
          <MessageDialog />
          <MessageDialogLogin />
          <MessageDialogLoginCliente />
          <ToastContainer autoClose={1500} />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
