import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { Container } from './styles';
import BarraTitulo from '../../components/BarraTitulo';
import PainelFiltro from '../../components/PainelFiltro';
import ClienteService from '../../services/ClienteService';
import MunicipioService from '../../services/MunicipioService';
import AuthService from '../../services/AuthService';
import history from '../../services/history';
import { StatusTela } from '../../util/Tipos';
import {
  buscaPageParams,
  errorHandle,
  validateFields,
  validCNPJ,
  padLeft,
} from '../../util/functions';
import Label from '../../components/Label';
import { ClienteModel } from '../../util/Models';
import { listaUfs } from '../../config/Constantes';
import DropdownLazy from '../../components/DropdownLazy';
import { showMessage } from '../../components/MessageDialog';
import InputTextSp from '../../components/InputTextSp';
import InputMaskSp from '../../components/InputMaskSp';
import DropdownSp from '../../components/DropdownSp';
import ContabilidadeService from '../../services/ContabilidadeService';
import PainelBotesCadastro from '../../components/PainelBotesCadastro';
import BotaoMenuGrid from '../../components/BotaoMenuGrid';
import ButtonSp from '../../components/ButtonSp';

export default function Cliente(props) {
  // useMemo
  const pageParams = useMemo(
    () => buscaPageParams(props.match.params, props.location.search),
    [props.location.search, props.match.params]
  );

  const filterService = useMemo(() => ClienteService.getFilter(), []);
  const usuarioLogado = useMemo(() => AuthService.getUsuario(), []);
  const contabilidadePadrao = usuarioLogado.contabilidade;

  // useStates
  const backTo = pageParams.backTo || '/clientes';
  const [filter, setFilter] = useState(filterService);
  const [clientes, setClientes] = useState([]);
  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [cliente, setCliente] = useState(new ClienteModel());
  const [ufSelecionado, setUfSelecionado] = useState('MG');
  const [contabilidadeSelecionada, setContabilidadeSelecionada] = useState();
  const [municipioSelecionado, setMunicipioSelecionado] = useState(null);

  // funcoes
  function modoConsulta() {
    return pageParams.statusTela === StatusTela.stVisualizar;
  }
  const handleBuscar = useCallback(async (_filter, _page) => {
    _filter.page = _page;
    try {
      const result = await ClienteService.findAll(_filter);
      setClientes(result.items);
      setPageLimit(result.limit);
      setTotalRecords(result.totalRecords);
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  const carregaMunicipio = useCallback(async (_nome, _uf) => {
    if (_nome !== '') {
      const r = await MunicipioService.findAll({ nome: _nome, uf: _uf, limit: 100 });
      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const carregaContabilidade = useCallback(async _nome => {
    if (_nome !== '') {
      const r = await ContabilidadeService.findAll({ nome: _nome, limit: 100 });
      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const carregaRegistro = useCallback(async _id => {
    const retorno = await ClienteService.findById(_id);
    const contabilidade = retorno.contabilidade
      ? {
          value: retorno.contabilidade.id,
          label: retorno.contabilidade.nome,
        }
      : null;
    const municipio = {
      label: retorno.municipio.nome,
      value: retorno.municipio.id,
    };
    setContabilidadeSelecionada(contabilidade);
    setMunicipioSelecionado(municipio);
    setCliente(retorno);
  }, []);

  function onPage(event) {
    const pagina = event.first / event.rows;
    setFirst(event.first);
    handleBuscar(filter, pagina);
  }

  // useCallbacks
  const excluirRegistro = useCallback(
    async _id => {
      try {
        await ClienteService.delete(_id);
        toast.success('Registro excluído com sucesso.');
        handleBuscar(filterService);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filterService, handleBuscar]
  );

  const confirmaExclusao = useCallback(
    idSelecionado => {
      showMessage('Confirmação', 'Confirma a exclusão do registro?', idx => {
        if (idx === 1) {
          excluirRegistro(idSelecionado);
        }
      });
    },
    [excluirRegistro]
  );

  // functions
  function handleVoltar() {
    if (pageParams.statusTela === StatusTela.stVisualizar) {
      history.push(backTo);
      handleBuscar(filter);
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          history.push(backTo);
          handleBuscar(filter);
        }
      });
    }
  }

  function handleSalvar() {
    if (pageParams.statusTela === StatusTela.stVisualizar) {
      history.push(backTo);
    } else {
      salvarRegistro();
    }
  }

  async function salvarRegistro() {
    if (!validCNPJ(cliente.cnpj, true)) {
      toast.warn('CNPJ inválido.');
      return;
    }

    try {
      if (pageParams.statusTela === StatusTela.stInserir) {
        await ClienteService.insert(cliente);
      } else {
        await ClienteService.update(cliente);
      }
      toast.success('Registro salvo com sucesso.');
      history.push(backTo);
      handleBuscar(filter);
    } catch (err) {
      errorHandle(err);
    }
  }
  // useEffects
  useEffect(() => {
    if (pageParams.statusTela === StatusTela.stPesquisar) {
      handleBuscar(filter, 0, 800);
    }
    // desativado para evitar que a cada vez que o usuario digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      pageParams.statusTela === StatusTela.stAlterar ||
      pageParams.statusTela === StatusTela.stVisualizar
    ) {
      carregaRegistro(pageParams.idSelecionado);
    } else if (pageParams.statusTela === StatusTela.stInserir) {
      const novo = new ClienteModel();
      if (contabilidadePadrao) {
        setContabilidadeSelecionada({
          value: contabilidadePadrao.id,
          label: contabilidadePadrao.nome,
        });
        novo.idContabilidade = contabilidadePadrao.id;
      }
      setMunicipioSelecionado(null);
      setCliente(novo);
    }
  }, [
    carregaRegistro,
    contabilidadePadrao,
    filterService,
    handleBuscar,
    pageParams.idSelecionado,
    pageParams.statusTela,
  ]);

  // exemplo de componentWillUnmount
  // useEffect(() => {
  //   return function cleanup() {
  //
  //   };
  // }, [filter]);

  // renders
  return (
    <Container className="container-page">
      <div className="p-grid">
        <BarraTitulo title="Clientes" />
        {pageParams.statusTela === StatusTela.stPesquisar
          ? renderPesquisa()
          : renderCadastro()}
      </div>
    </Container>
  );

  function renderPesquisa() {
    return (
      <>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleBuscar(filter);
          }}
        >
          <PainelFiltro className="p-grid p-col-12" visible="true">
            <div className="p-col-12 p-sm-9 p-lg-9 p-fluid">
              {/* <Label>Nome</Label> */}
              <Label>Razao Social</Label>
              <InputTextSp
                value={filter.razaoSocial}
                onChange={e => {
                  setFilter({ ...filter, razaoSocial: e.target.value });
                }}
              />
            </div>

            <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
              <Label>CNPJ</Label>
              <InputMaskSp
                id="txtCnpj"
                mask="99.999.999/9999-99"
                onChange={e => {
                  setFilter({ ...filter, cnpj: e.target.value });
                }}
              />
            </div>
          </PainelFiltro>
          <PainelBotesCadastro
            handleInserir={() => {
              history.push('/clientes/inserir');
            }}
            buttonInserirDisabled={!AuthService.checkRoles('CLIENTES_INSERIR')}
          />
        </form>
        <div className="p-col-12 p-fluid">
          <DataTable
            value={clientes}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            lazy
            responsive
            totalRecords={totalRecords}
            first={first}
            onPage={onPage}
          >
            <Column
              field="id"
              body={rowData => padLeft(rowData.id, 6)}
              header="Id"
              className="grid-col-id"
            />
            <Column field="razaoSocial" className="grid-col" header="Razão Social" />
            <Column field="telefone" className="grid-col-tel" header="Telefone" />
            <Column field="celular" className="grid-col-tel" header="Celular" />
            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTable>
        </div>
      </>
    );
  }

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => history.push(`/clientes/${rowData.id}?visualizar`),
          () => history.push(`/clientes/${rowData.id}`),
          () => confirmaExclusao(rowData.id),
          () =>
            history.push(
              `/clienteDocumentos?idCliente=${rowData.id}&idContabilidade=${rowData.idContabilidade}&backTo=/clientes`
            ),
        ]}
        disableds={[
          false,
          !AuthService.checkRoles('CLIENTES_ALTERAR'),
          !AuthService.checkRoles('CLIENTES_EXCLUIR'),
          !AuthService.checkRoles('CLIENTE_DOCUMENTOS_ACESSAR'),
        ]}
        labels={['Visualizar', 'Alterar', 'Excluir', 'Documentos']}
        icons={['pi pi-search', 'pi pi-pencil', 'pi pi-trash', 'pi pi-list']}
      />

      // ?idContabilidade=1&idCliente=2&idDocumentoTipo=3
    );
  }

  function renderCadastro() {
    return (
      <>
        <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
          <Label>Contabilidade</Label>
          <DropdownLazy
            required
            showClear
            disabled={
              AuthService.getUsuario().tipoUsuario !== 0 ||
              modoConsulta() ||
              pageParams.statusTela === StatusTela.stAlterar
            }
            placeholder="Selecione"
            onChange={e => {
              setCliente({
                ...cliente,
                idContabilidade: e?.value,
                contabilidade: e,
              });
            }}
            value={contabilidadeSelecionada}
            onFilter={async txtFilter => {
              const retorno = await carregaContabilidade(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
          <Label>Razão Social</Label>
          <InputTextSp
            value={cliente.razaoSocial}
            maxLength={100}
            required
            disabled={modoConsulta()}
            onChange={e => {
              setCliente({ ...cliente, razaoSocial: e.target.value });
            }}
          />
        </div>

        <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
          <Label>CNPJ</Label>
          <InputMaskSp
            id="txtCnpj"
            mask="99.999.999/9999-99"
            value={cliente.cnpj}
            required
            disabled={modoConsulta()}
            onChange={e => {
              setCliente({ ...cliente, cnpj: e.target.value });
            }}
          />
        </div>

        <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
          <Label>Gestor</Label>
          <InputTextSp
            value={cliente.nomeGestor}
            maxLength={100}
            disabled={modoConsulta()}
            onChange={e =>
              setCliente({
                ...cliente,
                nomeGestor: e.target.value,
              })
            }
          />
        </div>

        <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
          <Label>Logradouro</Label>
          <InputTextSp
            disabled={modoConsulta()}
            value={cliente.logradouro}
            maxLength={100}
            onChange={e =>
              setCliente({
                ...cliente,
                logradouro: e.target.value,
              })
            }
          />
        </div>

        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <Label>Nr.</Label>
          <InputTextSp
            value={cliente.numero}
            maxLength={8}
            disabled={modoConsulta()}
            onChange={e => setCliente({ ...cliente, numero: e.target.value })}
          />
        </div>

        <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
          <Label>Bairro</Label>
          <InputTextSp
            value={cliente.bairro}
            maxLength={100}
            disabled={modoConsulta()}
            onChange={e => setCliente({ ...cliente, bairro: e.target.value })}
          />
        </div>

        <div className="p-col-6 p-sm-4 p-lg-4 p-fluid">
          <Label>Complemento</Label>
          <InputTextSp
            value={cliente.complemento}
            maxLength={100}
            disabled={modoConsulta()}
            onChange={e =>
              setCliente({
                ...cliente,
                complemento: e.target.value,
              })
            }
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <Label>CEP</Label>
          <InputMaskSp
            id="txtCep"
            mask="99.999-999"
            disabled={modoConsulta()}
            value={cliente.cep}
            onChange={e => {
              setCliente({ ...cliente, cep: e.target.value });
            }}
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <Label>UF</Label>
          <DropdownSp
            disabled={modoConsulta()}
            value={ufSelecionado}
            options={listaUfs}
            filter
            showClear
            onChange={e => setUfSelecionado(e.target.value)}
          />
        </div>

        <div className="p-col-6 p-sm-4 p-lg-4 p-fluid">
          <Label>Município</Label>
          <DropdownLazy
            required
            showClear
            disabled={modoConsulta()}
            placeholder="Selecione"
            onChange={e => {
              setCliente({
                ...cliente,
                idMunicipio: e?.value,
              });
            }}
            value={municipioSelecionado}
            onFilter={async txtFilter => {
              const retorno = await carregaMunicipio(txtFilter, ufSelecionado);
              return retorno;
            }}
          />
        </div>
        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <Label>Telefone</Label>
          <InputMaskSp
            mask="(99) 9999-9999"
            disabled={modoConsulta()}
            value={cliente.telefone}
            onChange={e => {
              setCliente({ ...cliente, telefone: e.target.value });
            }}
          />
        </div>
        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <Label>Celular</Label>
          <InputMaskSp
            mask="(99) 99999-9999"
            disabled={modoConsulta()}
            value={cliente.celular}
            onChange={e => {
              setCliente({ ...cliente, celular: e.target.value });
            }}
          />
        </div>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <Label>email</Label>
          <InputTextSp
            disabled={modoConsulta()}
            value={cliente.email}
            maxLength={255}
            onChange={e =>
              setCliente({
                ...cliente,
                email: e.target.value,
              })
            }
          />
        </div>

        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <Label>site</Label>
          <InputTextSp
            disabled={modoConsulta()}
            value={cliente.site}
            maxLength={255}
            onChange={e =>
              setCliente({
                ...cliente,
                site: e.target.value,
              })
            }
          />
        </div>

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'end' }}>
          {!modoConsulta() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              disabled={
                !validateFields(cliente, [
                  'razaoSocial',
                  'idContabilidade',
                  'cnpj',
                  'idMunicipio',
                ])
              }
              showConfirmation
              onClick={handleSalvar}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            icon="pi pi-chevron-circle-left"
            onClick={handleVoltar}
          />
        </div>
      </>
    );
  }
}
