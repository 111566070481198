import React from 'react';
import { Switch } from 'react-router-dom';

import Home from '../pages/Home';
import Route from './Route';
import Login from '../pages/Login';
import Configuracoes from '../pages/Configuracoes';
import Contabilidade from '../pages/Contabilidade';
import Usuario from '../pages/Usuario';
import Cliente from '../pages/Cliente';
import AcessDenied from '../pages/AcessDenied';
import DocumentoTipo from '../pages/DocumentoTipo';
import ClienteDocumento from '../pages/ClienteDocumento';
import NotFound from '../pages/NotFound';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/login" exact component={Login} />
      <Route path="/denied" exact component={AcessDenied} isPrivate />
      <Route path="/home" component={Home} isPrivate />
      <Route
        path="/configuracoes"
        roles={['CONFIGURACOES_ACESSAR']}
        component={Configuracoes}
      />
      {/* Contabilidade */}
      <Route
        path="/contabilidades/:opcao"
        component={Contabilidade}
        roles={['CONTABILIDADES_ACESSAR', 'CONTABILIDADES_INSERIR', 'CONTABILIDADES_ALTERAR']}
      />
      <Route
        path="/contabilidades"
        component={Contabilidade}
        roles={['CONTABILIDADES_ACESSAR']}
      />
      {/* cliente */}
      <Route path="/clientes/:opcao" component={Cliente} roles={['CLIENTES_ACESSAR']} />
      <Route
        path="/clientes"
        component={Cliente}
        roles={['CLIENTES_ACESSAR', 'CLIENTES_INSERIR', 'CLIENTES_ALTERAR']}
      />
      {/* documento tipos */}
      <Route
        path="/documentoTipos/:opcao"
        component={DocumentoTipo}
        roles={['DOCUMENTO_TIPOS_ACESSAR']}
      />
      <Route
        path="/documentoTipos"
        component={DocumentoTipo}
        roles={[
          'DOCUMENTO_TIPOS_ACESSAR',
          'DOCUMENTO_TIPOS_INSERIR',
          'DOCUMENTO_TIPOS_ALTERAR',
        ]}
      />
      {/* usuarios  */}
      <Route path="/usuarios/:opcao" component={Usuario} roles={['USUARIOS_ACESSAR']} />
      <Route
        path="/usuarios"
        component={Usuario}
        roles={['USUARIOS_ACESSAR', 'USUARIOS_INSERIR', 'USUARIOS_ALTERAR']}
      />
      {/* cliente documento  */}
      <Route
        path="/clienteDocumentos/:opcao"
        component={ClienteDocumento}
        roles={['CLIENTE_DOCUMENTOS_ACESSAR']}
      />
      <Route
        path="/clienteDocumentos"
        component={ClienteDocumento}
        roles={[
          'CLIENTE_DOCUMENTOS_ACESSAR',
          'CLIENTE_DOCUMENTOS_INSERIR',
          'CLIENTE_DOCUMENTOS_ALTERAR',
        ]}
      />
      {/* se não encontrar nenhuma rota, vá para login */}
      <Route path="/" isPrivate component={NotFound} />
    </Switch>
  );
}
