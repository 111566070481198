import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { Container, ContainerCliente } from './styles';
import BarraTitulo from '../../components/BarraTitulo';
import PainelFiltro from '../../components/PainelFiltro';
import UsuarioService from '../../services/UsuarioService';
import ClienteService from '../../services/ClienteService';
import AuthService from '../../services/AuthService';
import history from '../../services/history';
import { StatusTela } from '../../util/Tipos';
import {
  buscaPageParams,
  errorHandle,
  validateFields,
  validCNPJ,
  padLeft,
} from '../../util/functions';
import Label from '../../components/Label';
import { UsuarioModel } from '../../util/Models';
import DropdownLazy from '../../components/DropdownLazy';
import { showMessage } from '../../components/MessageDialog';
import InputTextSp from '../../components/InputTextSp';
import DropdownSp from '../../components/DropdownSp';
import ContabilidadeService from '../../services/ContabilidadeService';
import PainelBotesCadastro from '../../components/PainelBotesCadastro';
import BotaoMenuGrid from '../../components/BotaoMenuGrid';
import ButtonSp from '../../components/ButtonSp';

export default function Usuario(props) {
  // useMemo
  const pageParams = useMemo(
    () => buscaPageParams(props.match.params, props.location.search),
    [props.location.search, props.match.params]
  );

  const filterService = useMemo(() => UsuarioService.getFilter(), []);
  const usuarioLogado = useMemo(() => AuthService.getUsuario(), []);
  const contabilidadePadrao = usuarioLogado.contabilidade;
  const contabilidadeDropdow = contabilidadePadrao
    ? { label: contabilidadePadrao.nome, value: contabilidadePadrao.id }
    : null;

  // useStates
  const backTo = pageParams.backTo || '/usuarios';

  const [filter, setFilter] = useState(filterService);
  const [usuarios, setUsuarios] = useState([]);
  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [usuario, setUsuario] = useState(new UsuarioModel());

  const [contabilidadeSelecionada, setContabilidadeSelecionada] = useState();
  const [contabilidadePesquisaSelecionada, setContabilidadePesquisaSelecionada] = useState(
    contabilidadeDropdow
  );

  const [clientePesquisaSelecionado, setClientePesquisaSelecionado] = useState(null);
  const [usuarioClientes, setUsuarioClientes] = useState([]);
  const [clienteUsuarioSelecionado, setClienteUsuarioSelecionado] = useState(null);

  // funcoes
  function modoConsulta() {
    return pageParams.statusTela === StatusTela.stVisualizar;
  }

  const handleBuscar = useCallback(async (_filter, _page) => {
    _filter.page = _page;

    try {
      const result = await UsuarioService.findAll(_filter);
      setUsuarios(result.items);
      setPageLimit(result.limit);
      setTotalRecords(result.totalRecords);
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  const carregaCliente = useCallback(async (_razaoSocial, _idContabilidade = -1) => {
    if (_razaoSocial !== '') {
      const r = await ClienteService.findAll({
        razaoSocial: _razaoSocial,
        idContabilidade: _idContabilidade,
        limit: 100,
      });
      const retorno = r.items.map(e => {
        return {
          label: e.razaoSocial,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const carregaContabilidade = useCallback(async _nome => {
    if (_nome !== '') {
      const r = await ContabilidadeService.findAll({ nome: _nome, limit: 100 });
      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const carregaRegistro = useCallback(async _id => {
    try {
      const retorno = await UsuarioService.findById(_id);
      const contabilidade = retorno.contabilidade
        ? {
            value: retorno.contabilidade.id,
            label: retorno.contabilidade.nome,
          }
        : null;

      const listaUsuarioClientes = retorno.usuarioClientes.map(e => {
        return {
          id: e.cliente.id,
          razaoSocial: e.cliente.razaoSocial,
        };
      });

      setUsuarioClientes(listaUsuarioClientes);
      setContabilidadeSelecionada(contabilidade);
      setUsuario(retorno);
      setClienteUsuarioSelecionado(null);
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  function onPage(event) {
    const pagina = event.first / event.rows;
    setFirst(event.first);
    handleBuscar(filter, pagina);
  }

  // useCallbacks
  const excluirRegistro = useCallback(
    async _id => {
      try {
        await UsuarioService.delete(_id);
        toast.success('Registro excluído com sucesso.');
        handleBuscar(filterService);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filterService, handleBuscar]
  );

  const confirmaExclusao = useCallback(
    idSelecionado => {
      showMessage('Confirmação', 'Confirma a exclusão do registro?', idx => {
        if (idx === 1) {
          excluirRegistro(idSelecionado);
        }
      });
    },
    [excluirRegistro]
  );

  // functions
  function handleVoltar() {
    if (pageParams.statusTela === StatusTela.stVisualizar) {
      history.push(backTo);
      handleBuscar(filter);
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          history.push(backTo);
          handleBuscar(filter);
        }
      });
    }
  }

  function handleSalvar() {
    if (pageParams.statusTela === StatusTela.stVisualizar) {
      history.push(backTo);
    } else {
      salvarRegistro();
    }
  }

  function handleExcluirCliente(obj) {
    const lista = [...usuarioClientes]; // fazendo uma copia
    const idx = lista.indexOf(obj);
    if (idx >= 0) {
      lista.splice(idx, 1);
    }
    setUsuarioClientes(lista);
  }

  function handleInserirCliente() {
    const lista = [...usuarioClientes]; // fazendo uma copia

    if (!lista.find(e => e.id === clienteUsuarioSelecionado.value)) {
      lista.push({
        id: clienteUsuarioSelecionado.value,
        razaoSocial: clienteUsuarioSelecionado.label,
      });
      setUsuarioClientes(lista);
    } else {
      toast.warn('Cliente já existe na lista.');
    }
  }

  const confirmaExclusaoClienteclusao = obj => {
    showMessage('Confirmação', 'Confirma a exclusão do registro?', idx => {
      if (idx === 1) {
        handleExcluirCliente(obj);
      }
    });
  };

  async function salvarRegistro() {
    if (!validCNPJ(usuario.cnpj, true)) {
      toast.warn('CNPJ inválido.');
      return;
    }

    if (usuario.senha || usuario.confirmSenha) {
      if (usuario.senha !== usuario.confirmSenha) {
        toast.warn('Senha e confirmação não conferem.');
        return;
      }
    }

    // Ajustando lista de usuarios
    if (usuario.tipoUsuario === 2) {
      // excluindo elementos que não existem em usuarioClientes

      const listaClientes = usuarioClientes.map(e => {
        const clienteExist = usuario.usuarioClientes.find(u => u.idCliente === e.id);
        const retorno = {
          cliente: e,
          idCliente: e.id,
          idUsuario: usuario.id,
        };
        if (clienteExist) {
          retorno.id = clienteExist.id;
        }
        return retorno;
      });
      usuario.usuarioClientes = listaClientes;
    } else {
      usuario.usuarioClientes = [];
    }

    try {
      if (pageParams.statusTela === StatusTela.stInserir) {
        await UsuarioService.insert(usuario);
      } else {
        await UsuarioService.update(usuario);
      }
      toast.success('Registro salvo com sucesso.');
      history.push(backTo);
      handleBuscar(filter);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (pageParams.statusTela === StatusTela.stPesquisar) {
      handleBuscar(filter, 0, 800);
    }
    // desativado para evitar que a cada vez que o usuario digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      pageParams.statusTela === StatusTela.stAlterar ||
      pageParams.statusTela === StatusTela.stVisualizar
    ) {
      carregaRegistro(pageParams.idSelecionado);
    } else if (pageParams.statusTela === StatusTela.stInserir) {
      const novo = new UsuarioModel();
      if (contabilidadePadrao) {
        setContabilidadeSelecionada({
          value: contabilidadePadrao.id,
          label: contabilidadePadrao.nome,
        });
        novo.idContabilidade = contabilidadePadrao.id;
      } else {
        setContabilidadeSelecionada(null);
      }
      novo.tipoUsuario = 2;
      setUsuario(novo);
    }
  }, [
    carregaRegistro,
    contabilidadePadrao,
    handleBuscar,
    pageParams.idSelecionado,
    pageParams.statusTela,
  ]);

  // exemplo de componentWillUnmount
  // useEffect(() => {
  //   return function cleanup() {
  //
  //   };
  // }, [filter]);

  // renders
  return (
    <Container className="container-page">
      <div className="p-grid">
        <BarraTitulo title="Usuarios" />
        {pageParams.statusTela === StatusTela.stPesquisar
          ? renderPesquisa()
          : renderCadastro()}
      </div>
    </Container>
  );

  function renderPesquisa() {
    return (
      <>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleBuscar(filter);
          }}
        >
          <PainelFiltro className="p-grid p-col-12" visible="true">
            <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
              <Label>Nome</Label>
              <InputTextSp
                value={filter.nome}
                onChange={e => {
                  setFilter({ ...filter, nome: e.target.value });
                }}
              />
            </div>
            <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
              <Label>email</Label>
              <InputTextSp
                value={filter.email}
                onChange={e => {
                  setFilter({ ...filter, email: e.target.value });
                }}
              />
            </div>

            <div className="p-col-3 p-sm-3 p-lg-3 p-fluid">
              <Label>Contabilidade</Label>
              <DropdownLazy
                showClear
                disabled={modoConsulta() || AuthService.getUsuario().tipoUsuario !== 0}
                placeholder="Selecione"
                onChange={e => {
                  setFilter({ ...filter, idContabilidade: e?.value });
                  setContabilidadePesquisaSelecionada(e);
                }}
                value={contabilidadePesquisaSelecionada}
                onFilter={async txtFilter => {
                  const retorno = await carregaContabilidade(txtFilter);
                  return retorno;
                }}
              />
            </div>
            <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
              <Label>Cliente</Label>
              <DropdownLazy
                showClear
                disabled={modoConsulta()}
                placeholder="Selecione"
                onChange={e => {
                  setFilter({ ...filter, idCliente: e?.value });
                  setClientePesquisaSelecionado(e);
                }}
                value={clientePesquisaSelecionado}
                onFilter={async txtFilter => {
                  const retorno = await carregaCliente(
                    txtFilter,
                    contabilidadePesquisaSelecionada.id
                  );
                  return retorno;
                }}
              />
            </div>
          </PainelFiltro>
          <PainelBotesCadastro
            handleInserir={() => {
              history.push('/usuarios/inserir');
            }}
            handleBuscar={() => {
              handleBuscar(filter);
            }}
            buttonInserirDisabled={!AuthService.checkRoles('CLIENTES_INSERIR')}
          />

          <div className="p-col-12 p-fluid">
            <DataTable
              value={usuarios}
              style={{ marginBottom: '2px' }}
              paginator
              rows={pageLimit}
              lazy
              responsive
              totalRecords={totalRecords}
              first={first}
              onPage={onPage}
            >
              <Column
                field="id"
                body={rowData => padLeft(rowData.id, 6)}
                header="Id"
                className="grid-col-id"
              />
              <Column field="nome" className="grid-col" header="Nome" />
              <Column field="email" className="grid-col" header="email" />
              <Column
                className="grid-col"
                style={{ width: 110 }}
                header="Tipo"
                body={rowData => {
                  if (rowData.tipoUsuario === 0) {
                    return 'Administrador';
                  }
                  if (rowData.tipoUsuario === 1) {
                    return 'Contabilidade';
                  }
                  if (rowData.tipoUsuario === 2) {
                    return 'Cliente';
                  }
                }}
              />

              <Column
                className="gid-col-acoes-35"
                bodyStyle={{ textAlign: 'end' }}
                body={renderButtonOp}
              />
            </DataTable>
          </div>
        </form>
      </>
    );
  }

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => history.push(`/usuarios/${rowData.id}?visualizar`),
          () => history.push(`/usuarios/${rowData.id}`),
          () => confirmaExclusao(rowData.id),
        ]}
        disableds={[
          false,
          !AuthService.checkRoles('CLIENTES_ALTERAR'),
          !AuthService.checkRoles('CLIENTES_EXCLUIR'),
        ]}
      />
    );
  }

  function renderButtonExlcuirCliente(rowData) {
    return (
      <ButtonSp
        disabled={modoConsulta()}
        className="botao-pequeno p-button-danger buttons"
        onClick={() => confirmaExclusaoClienteclusao(rowData)}
        icon="pi pi-trash"
      />
    );
  }

  function renderCadastro() {
    return (
      <>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <Label>email</Label>
          <InputTextSp
            disabled={modoConsulta()}
            value={usuario.email}
            maxLength={255}
            required
            onChange={e =>
              setUsuario({
                ...usuario,
                email: e.target.value,
              })
            }
          />
        </div>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <Label>Nome</Label>
          <InputTextSp
            value={usuario.nome}
            maxLength={40}
            required
            disabled={modoConsulta()}
            onChange={e => {
              setUsuario({ ...usuario, nome: e.target.value });
            }}
          />
        </div>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <Label>Tipo</Label>
          <DropdownSp
            required
            value={usuario.tipoUsuario}
            options={[
              { label: 'Administrador', value: 0 },
              { label: 'Contabilidade', value: 1 },
              { label: 'Cliente', value: 2 },
            ]}
            disabled={modoConsulta() || AuthService.getUsuario().tipoUsuario !== 0}
            filterInputAutoFocus={false}
            onChange={e => {
              setUsuario({
                ...usuario,
                tipoUsuario: e.target.value,
                idCliente: null,
                idContabilidade: e.target.value === 0 ? null : usuario.idContabilidade,
              });
              if (e.target.value === 0) {
                setContabilidadeSelecionada(null);
              }
              if (e.target.value !== 2) {
                setUsuarioClientes([]);
                setClienteUsuarioSelecionado(null);
              }
            }}
          />
        </div>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <Label>Contabilidade</Label>
          <DropdownLazy
            required
            showClear
            disabled={
              modoConsulta() ||
              pageParams.statusTela === StatusTela.stAlterar ||
              AuthService.getUsuario().tipoUsuario !== 0 ||
              usuario.tipoUsuario === 0
            }
            placeholder="Selecione"
            onChange={e => {
              setUsuario({
                ...usuario,
                idContabilidade: e?.value,
              });
              setContabilidadeSelecionada(e);
              setUsuarioClientes([]);
              setClienteUsuarioSelecionado(null);
            }}
            value={contabilidadeSelecionada}
            onFilter={async txtFilter => {
              const retorno = await carregaContabilidade(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <Label>Senha</Label>
          <InputTextSp
            type="password"
            name="password"
            disabled={modoConsulta()}
            required={
              pageParams.statusTela === StatusTela.stInserir ||
              (usuario.confirmSenha && usuario.confirmSenha !== '')
            }
            value={usuario.senha}
            onChange={e => {
              setUsuario({ ...usuario, senha: e.target.value });
            }}
          />
        </div>
        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <Label>Confirmação</Label>
          <InputTextSp
            type="password"
            name="password"
            disabled={modoConsulta()}
            required={
              pageParams.statusTela === StatusTela.stInserir ||
              (usuario.senha && usuario.senha !== '')
            }
            value={usuario.confirmSenha}
            onChange={e => {
              setUsuario({ ...usuario, confirmSenha: e.target.value });
            }}
          />
        </div>
        {/* Area da grid de clientes */}
        {usuario.tipoUsuario === 2 ? (
          <ContainerCliente>
            <div className="p-grid" style={{ padding: 8 }}>
              <div className="p-col-12 p-fluid" style={{ textAlign: 'center' }}>
                <Label>Clientes</Label>
              </div>

              <div className="p-col-6 p-fluid">
                <Label>Cliente</Label>
                <DropdownLazy
                  showClear
                  disabled={modoConsulta()}
                  placeholder="Selecione"
                  onChange={e => {
                    setClienteUsuarioSelecionado(e);
                  }}
                  value={clienteUsuarioSelecionado}
                  onFilter={async txtFilter => {
                    const retorno = await carregaCliente(txtFilter, usuario.idContabilidade);
                    return retorno;
                  }}
                />
              </div>

              <div className="p-col-6">
                <Label>&#160;</Label>
                <ButtonSp
                  label="Inserir"
                  onClick={() => handleInserirCliente()}
                  disabled={modoConsulta() || !clienteUsuarioSelecionado}
                />
              </div>

              <div className="p-col-12 p-fluid">
                <DataTable
                  value={usuarioClientes}
                  style={{ marginBottom: '2px' }}
                  paginator
                  rows={5}
                  responsive
                >
                  <Column
                    field="id"
                    body={rowData => padLeft(rowData.id, 6)}
                    header="Id"
                    className="grid-col-id"
                  />
                  <Column field="razaoSocial" className="grid-col" header="Nome" />

                  <Column
                    className="gid-col-acoes-35"
                    bodyStyle={{ textAlign: 'end' }}
                    body={renderButtonExlcuirCliente}
                  />
                </DataTable>
              </div>
            </div>
          </ContainerCliente>
        ) : null}
        <div className="p-col-12 p-lg-12" style={{ textAlign: 'end' }}>
          {!modoConsulta() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              disabled={
                (usuario.tipoUsuario === 2 && usuarioClientes.length === 0) ||
                !validateFields(usuario, [
                  'nome',
                  'email',
                  usuario.tipoUsuario !== 0 ? 'idContabilidade' : '',
                  pageParams.statusTela === StatusTela.stInserir ? 'senha' : '',
                  pageParams.statusTela === StatusTela.stInserir ? 'confirmSenha' : '',
                ])
              }
              showConfirmation
              onClick={handleSalvar}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            icon="pi pi-chevron-circle-left"
            onClick={handleVoltar}
          />
        </div>
      </>
    );
  }
}
