import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';
import { geraFiltroHttp, cloneObj, addMes, primeiroDiaMes } from '../util/functions';
import api from './api';

class ClienteDocumentoService extends BaseService {
  constructor() {
    super(`${ConfigApi.clienteDocumentoURL}`);
    this.filter = new Filter();
  }

  getFilter() {
    return this.filter;
  }

  setFilter(filter) {
    this.filter = filter;
  }

  async downloadDocumento(uri) {
    this.setLoading(true);
    try {
      const response = await api.get(uri, { responseType: 'blob' });
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async findAll(filter) {
    const localFilter = cloneObj(filter);
    this.setLoading(true);
    try {
      // obriga os filtros idCliente e idContabilidade
      if (!localFilter.idCliente) {
        localFilter.idCliente = -1;
      }
      if (!localFilter.idContabilidade) {
        localFilter.idContabilidade = -1;
      }

      const filterGet = geraFiltroHttp(localFilter);
      const response = await api.get(`${this.url}${filterGet}`);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async insert(body) {
    delete body.id;

    const data = new FormData();
    Object.keys(body).forEach(k => {
      data.append(k, body[k]);
    });

    this.setLoading(true);
    try {
      const response = await api.post(this.url, data);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async update(body) {
    const data = new FormData();
    Object.keys(body).forEach(k => {
      data.append(k, body[k]);
    });
    try {
      const response = await api.put(`${this.url}/${body.id}`, data);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }
}

class Filter {
  constructor() {
    this.idContabilidade = 0;
    this.idCliente = 0;
    this.idDocumentoTipo = 0;
    this.dataInicial = primeiroDiaMes(addMes(new Date(), -12));
    this.dataFinal = primeiroDiaMes(new Date());
    this.limit = 11;
    this.page = 0;
  }
}
export default new ClienteDocumentoService();
