import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import jwt from 'jsonwebtoken';
import api from '../../../services/api';
import { loginSuccess } from './actions';
import { loadingSuccess, loading } from '../global/actions';
import { saveConfigApi } from '../config/actions';
import { hiddenDialogLogin, showDialogClienteLogin } from '../dialog/actions';
import authConfig from '../../../config/auth';

export function* doLogin({ payload, redirectTo }) {
  yield put(loading());
  const { email, senha, idCliente } = payload;
  try {
    const response = yield call(api.post, 'sessions', {
      email,
      senha,
      idCliente,
    });
    const { token, usuario, configuracao, apiInfo } = response.data;
    const decodedToken = jwt.verify(token, authConfig.secret);

    api.defaults.headers.Authorization = `Bearer ${token}`;

    localStorage.setItem(
      'apiInfo',
      JSON.stringify({
        apiVersion: apiInfo.versionNumber,
        dateLastUpdate: new Date(),
      })
    );

    yield put(loginSuccess(token, decodedToken, usuario));
    yield put(loadingSuccess());
    yield put(saveConfigApi(configuracao.tiposDocumento));
    yield put(hiddenDialogLogin());

    if (redirectTo && redirectTo !== '') {
      window.location = redirectTo; // força recarregar a pagina
    }
  } catch (err) {
    yield put(loadingSuccess());
    // avaliando o retorno
    if (
      err &&
      err.response &&
      err.response.status === 400 &&
      err.response.data &&
      err.response.data.codInternoError === 1
    ) {
      // toast.error('Selecione o cliente');
      // abre tela para selecionar o cliente
      yield put(showDialogClienteLogin(email, senha, err.response.data.detalhe));
    } else {
      toast.error('Falha na autenticação, verifique seus dados');
    }
  }
}
export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function doLogout() {
  // tive que usar o setTimeout para evitar um problema que a tela travava no telefone
  // setTimeout(() => history.push('/'), 200);
  setTimeout(() => window.location.reload(), 200);
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/LOGIN_REQUEST', doLogin),
  takeLatest('@auth/LOGOUT', doLogout),
]);
