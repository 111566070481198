import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { Container } from './styles';
import BarraTitulo from '../../components/BarraTitulo';
import PainelFiltro from '../../components/PainelFiltro';
import DocumentoTipoService from '../../services/DocumentoTipoService';
import history from '../../services/history';
import { StatusTela } from '../../util/Tipos';
import {
  buscaPageParams,
  errorHandle,
  validateFields,
  validCNPJ,
  validCPF,
  padLeft,
  isMobile,
} from '../../util/functions';
import Label from '../../components/Label';
import { DocumentoTipoModel } from '../../util/Models';
import DropdownLazy from '../../components/DropdownLazy';
import { showMessage } from '../../components/MessageDialog';
import InputTextSp from '../../components/InputTextSp';
import DropdownSp from '../../components/DropdownSp';
import AuthService from '../../services/AuthService';
import PainelBotesCadastro from '../../components/PainelBotesCadastro';
import BotaoMenuGrid from '../../components/BotaoMenuGrid';
import ButtonSp from '../../components/ButtonSp';

export default function DocumentoTipo(props) {
  // useMemo
  const pageParams = useMemo(
    () => buscaPageParams(props.match.params, props.location.search),
    [props.location.search, props.match.params]
  );
  const filterService = useMemo(() => DocumentoTipoService.getFilter(), []);

  // useStates
  const backTo = '/documentoTipos';
  const [filter, setFilter] = useState(filterService);
  const [documentoTipos, setDocumentoTipos] = useState([]);
  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [documentoTipo, setDocumentoTipo] = useState(new DocumentoTipoModel());

  const [documentoTipoSelecionado, setDocumentoTipoSelecionado] = useState(null);

  // funcoes
  const handleBuscar = useCallback(async (_filter, _page) => {
    _filter.page = _page;
    try {
      const result = await DocumentoTipoService.findAll(_filter);
      setDocumentoTipos(result.items);
      setPageLimit(result.limit);
      setTotalRecords(result.totalRecords);
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  const documentoTipoSintetico = useCallback(async _nome => {
    if (_nome !== '') {
      const r = await DocumentoTipoService.findAll({ nome: _nome, sinteticoAnalitico: 'S' });
      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const carregaRegistro = useCallback(async _id => {
    const retorno = await DocumentoTipoService.findById(_id);
    setDocumentoTipo(retorno);
    setDocumentoTipoSelecionado(null);
  }, []);

  function onPage(event) {
    const pagina = event.first / event.rows;
    setFirst(event.first);
    handleBuscar(filter, pagina);
  }

  // useCallbacks
  const excluirRegistro = useCallback(
    async _id => {
      try {
        await DocumentoTipoService.delete(_id);
        toast.success('Registro excluído com sucesso.');
        handleBuscar(filterService);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filterService, handleBuscar]
  );

  const confirmaExclusao = useCallback(
    idSelecionado => {
      showMessage('Confirmação', 'Confirma a exclusão do registro?', idx => {
        if (idx === 1) {
          excluirRegistro(idSelecionado);
        }
      });
    },
    [excluirRegistro]
  );

  // functions
  function handleVoltar() {
    if (pageParams.statusTela === StatusTela.stVisualizar) {
      history.push(backTo);
      handleBuscar(filter);
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          history.push(backTo);
          handleBuscar(filter);
        }
      });
    }
  }

  function handleSalvar() {
    if (pageParams.statusTela === StatusTela.stVisualizar) {
      history.push(backTo);
    } else {
      salvarRegistro();
    }
  }

  function modoConsulta() {
    return pageParams.statusTela === StatusTela.stVisualizar;
  }

  async function salvarRegistro() {
    if (!validCNPJ(documentoTipo.cnpj, true)) {
      toast.warn('CNPJ inválido.');
      return;
    }
    if (!validCPF(documentoTipo.cpf, true)) {
      toast.warn('CPF inválido.');
      return;
    }
    try {
      if (pageParams.statusTela === StatusTela.stInserir) {
        await DocumentoTipoService.insert(documentoTipo);
      } else {
        await DocumentoTipoService.update(documentoTipo);
      }
      toast.success('Registro salvo com sucesso.');
      history.push(backTo);
      handleBuscar(filter);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (pageParams.statusTela === StatusTela.stPesquisar) {
      handleBuscar(filter, 0, 800);
    }
    // desativado para evitar que a cada vez que o usuario digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      pageParams.statusTela === StatusTela.stAlterar ||
      pageParams.statusTela === StatusTela.stVisualizar
    ) {
      carregaRegistro(pageParams.idSelecionado);
    } else if (pageParams.statusTela === StatusTela.stInserir) {
      const novo = new DocumentoTipoModel();
      setDocumentoTipoSelecionado(null);
      setDocumentoTipo(novo);
    }
  }, [
    carregaRegistro,
    filterService,
    handleBuscar,
    pageParams.idSelecionado,
    pageParams.statusTela,
  ]);

  // renders
  return (
    <Container className="container-page">
      <div className="p-grid">
        <BarraTitulo title="Tipos de Documento" />
        {pageParams.statusTela === StatusTela.stPesquisar
          ? renderPesquisa()
          : renderCadastro()}
      </div>
    </Container>
  );

  function renderPesquisa() {
    return (
      <>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleBuscar(filter);
          }}
        >
          <PainelFiltro className="p-grid p-col-12" visible="true">
            <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
              {/* <Label>Nome</Label> */}
              <Label>Nome</Label>
              <InputTextSp
                value={filter.nome}
                onChange={e => {
                  setFilter({ ...filter, nome: e.target.value });
                }}
              />
            </div>

            <PainelBotesCadastro
              handleInserir={() => {
                history.push('/documentoTipos/inserir');
              }}
              buttonInserirDisabled={!AuthService.checkRoles('DOCUMENTO_TIPOS_INSERIR')}
            />
          </PainelFiltro>
        </form>
        <div className="p-col-12 p-fluid">
          <DataTable
            value={documentoTipos}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            lazy
            responsive
            totalRecords={totalRecords}
            first={first}
            onPage={onPage}
          >
            <Column
              field="id"
              body={rowData => padLeft(rowData.id, 6)}
              header="Id"
              className="grid-col-id"
            />
            <Column
              field="sinteticoAnalitico"
              className="grid-col-id"
              bodyStyle={{ textAlign: isMobile() ? 'start' : 'center' }}
              header="Tipo"
            />
            <Column
              field="idDocumentoTipo"
              className="grid-col-data"
              header="Id Tipo Doc."
              body={rowData => padLeft(rowData.idDocumentoTipo, 6)}
            />
            <Column field="nome" className="grid-col" header="Nome" />
            <Column
              field="extensaoArquivo"
              className="grid-col-data"
              bodyStyle={{ textAlign: isMobile() ? 'start' : 'center' }}
              header="Tipo Arq."
            />
            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTable>
        </div>
      </>
    );
  }

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => history.push(`/documentoTipos/${rowData.id}?visualizar`),
          () => history.push(`/documentoTipos/${rowData.id}`),
          () => confirmaExclusao(rowData.id),
        ]}
        disableds={[
          false,
          !AuthService.checkRoles('DOCUMENTO_TIPOS_ALTERAR'),
          !AuthService.checkRoles('DOCUMENTO_TIPOS_EXCLUIR'),
        ]}
      />
    );
  }

  function renderCadastro() {
    return (
      <>
        <div className="p-col-8 p-sm-5 p-lg-5 p-fluid">
          <Label>Nome</Label>
          <InputTextSp
            value={documentoTipo.nome}
            maxLength={100}
            required
            disabled={modoConsulta()}
            onChange={e => {
              setDocumentoTipo({ ...documentoTipo, nome: e.target.value });
            }}
          />
        </div>

        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <Label>Tipo</Label>
          <DropdownSp
            value={documentoTipo.sinteticoAnalitico}
            disabled={modoConsulta()}
            options={[
              { label: 'Analítico', value: 'A' },
              { label: 'Sintético', value: 'S' },
            ]}
            filterInputAutoFocus={false}
            onChange={e => {
              if (e.target.value === 'S') {
                setDocumentoTipo({
                  ...documentoTipo,
                  extensaoArquivo: '',
                  idDocumentoTipo: null,
                  sinteticoAnalitico: e.target.value,
                });
                setDocumentoTipoSelecionado(null);
              } else {
                setDocumentoTipo({ ...documentoTipo, sinteticoAnalitico: e.target.value });
              }
            }}
          />
        </div>

        <div className="p-col-8 p-sm-3 p-lg-3 p-fluid">
          <Label>Tipo Doc. Sintético</Label>
          <DropdownLazy
            required
            showClear
            disabled={modoConsulta() || documentoTipo.sinteticoAnalitico === 'S'}
            placeholder="Selecione"
            onChange={e => {
              setDocumentoTipo({
                ...documentoTipo,
                idDocumentoTipo: e?.value,
              });
              setDocumentoTipoSelecionado(e);
            }}
            value={documentoTipoSelecionado}
            onFilter={async txtFilter => {
              const retorno = await documentoTipoSintetico(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <Label>Tp. Arquivo</Label>
          <DropdownSp
            disabled={modoConsulta() || documentoTipo.sinteticoAnalitico === 'S'}
            value={documentoTipo.extensaoArquivo}
            options={[
              { label: 'PDF', value: 'pdf' },
              { label: 'ZIP', value: 'zip' },
              { label: 'XML', value: 'xml' },
              { label: 'TXT', value: 'txt' },
            ]}
            required
            filterInputAutoFocus={false}
            onChange={e => {
              setDocumentoTipo({ ...documentoTipo, extensaoArquivo: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'end' }}>
          {/* <Label className="label-button">&#160;</Label> */}
          {!modoConsulta() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              disabled={
                !validateFields(documentoTipo, [
                  'nome',
                  documentoTipo.sinteticoAnalitico === 'A' ? 'idDocumentoTipo' : '',
                  documentoTipo.sinteticoAnalitico === 'A' ? 'extensaoArquivo' : '',
                ])
              }
              showConfirmation
              onClick={handleSalvar}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            icon="pi pi-chevron-circle-left"
            onClick={handleVoltar}
          />
        </div>
      </>
    );
  }
}
