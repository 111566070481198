import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';

import logo from '../../assets/images/logo300.png';
import { Container } from './styles';

import { loginRequest } from '../../store/modules/auth/actions';
import { validateFields } from '../../util/functions';

export default function Login() {
  const [email, setEmail] = useState([]);
  const [password, setPassword] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setEmail('');
    setPassword('');
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(loginRequest(email, password, '/home'));
  }

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <img src={logo} alt="" />
          </div>

          <div className="p-col-12">
            <InputText
              autoFocus
              type="text"
              name="login"
              placeholder="email"
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>

          <div className="p-col-12">
            <InputText
              type="password"
              name="password"
              placeholder="password"
              required
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>

          <div className="p-col-12">
            <Button
              type="submit"
              label="Login"
              disabled={!validateFields({ email, password }, ['email', 'password'])}
            />
          </div>
        </div>
      </form>
    </Container>
  );
}
