/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

// import { Container } from './styles';

export default function Label(props) {
  return (
    <div style={{ paddingBottom: 3 }}>
      <label style={{ fontWeight: 'bold', ...props.style }}>{props.children}</label>
    </div>
  );
}
