import React from 'react';
import { InputMask } from 'primereact/inputmask';
import PropTypes from 'prop-types';
// import { Container } from './styles';

export default function InputMaskSp(props) {
  InputMaskSp.propTypes = {
    required: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };

  InputMaskSp.defaultProps = {
    required: false,
    className: '',
    disabled: false,
  };

  const { value, required, disabled, className, ...rest } = props;

  const classNames = required && !disabled ? `p-error ${className || ''}` : className || '';

  return (
    <InputMask {...rest} disabled={disabled} value={value || ''} className={classNames} />
  );
}
