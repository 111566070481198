import jwt from 'jsonwebtoken';

import { store } from '../store';

import authConfig from '../config/auth';
import { isValidArray } from '../util/functions';

class AuthService {
  acessToken() {
    const { token } = store.getState().auth;
    try {
      const decoded = jwt.verify(token, authConfig.secret);
      return {
        signed: true,
        exp: decoded.exp,
        usuario: decoded.usuario,
        token,
      };
    } catch (error) {
      return {
        signed: false,
        exp: 0,
        token: '',
      };
    }
  }

  getUsuario() {
    const { decodedToken } = store.getState().auth;

    try {
      return decodedToken.usuario;
    } catch (error) {
      return null;
    }
  }

  checkRoles(arrayNames) {
    const { decodedToken } = store.getState().auth;
    if (!decodedToken) {
      return false;
    }
    const { roles } = decodedToken;
    if (!isValidArray(arrayNames)) {
      arrayNames = arrayNames ? [arrayNames] : [];
    }
    let ret = false;
    arrayNames.forEach(e => {
      if (roles.includes(e)) {
        ret = true;
        return true;
      }
    });

    return ret;
  }

  // async checkVersaoApi() {
  //   const dataAtual = new Date();
  //   const { versaoApi, dataUltimaVersao } = store.getState().config;
  //   let retorno = true;
  //   let versao = versaoApi;
  //   let atualizarApiInfo = false;
  //   const ultimaData = new Date(dataUltimaVersao);

  //   if (Math.abs(differenceInMinutes(ultimaData, dataAtual)) > 0) {
  //     const apiInfo = await ApiInfoService.apiInfo();

  //     versao = apiInfo.versionNumber;
  //     atualizarApiInfo = true;
  //   }

  //   if (versaoApi !== versao) {
  //     atualizarApiInfo = true;
  //     retorno = false;
  //   }
  //   if (atualizarApiInfo) {
  //     store.dispatch(setVersaoApi(versao));
  //   }

  //   return retorno;
  // }
}

export default new AuthService();
