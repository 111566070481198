export function loginRequest(email, senha, redirectTo, idCliente) {
  return {
    type: '@auth/LOGIN_REQUEST',
    payload: { email, senha, idCliente },
    redirectTo,
  };
}

export function loginSuccess(token, decodedToken, usuario) {
  return {
    type: '@auth/LOGIN_SUCCESS',
    payload: { token, decodedToken, usuario },
  };
}

export function loginFailure() {
  return {
    type: '@auth/LOGIN_FAILURE',
  };
}

export function logout() {
  return {
    type: '@auth/LOGOUT',
  };
}
