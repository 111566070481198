import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';

class ContabilidadeService extends BaseService {
  constructor() {
    super(`${ConfigApi.contabilidadeURL}`);
    this.filter = new Filter();
  }

  getFilter() {
    return this.filter;
  }

  setFilter(filter) {
    this.filter = filter;
  }
}

class Filter {
  constructor() {
    this.nome = '';
    this.cpf = '';
    this.cnpj = '';
    this.limit = 10;
    this.page = 0;
  }
}
export default new ContabilidadeService();
