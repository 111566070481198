import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;
`;

export const ContainerCliente = styled.div`
  border: 1px solid #969595;
  border-radius: 4px;
  margin: 10 !important;
  padding: 10 !important;
`;

/* Dashboard */
