import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import path from 'path';
import queryString from 'query-string';
import { Container } from './styles';
import BarraTitulo from '../../components/BarraTitulo';
import PainelFiltro from '../../components/PainelFiltro';
import ClienteDocumentoService from '../../services/ClienteDocumentoService';
import DocumentoTipoService from '../../services/DocumentoTipoService';
import { StatusTela } from '../../util/Tipos';
import {
  buscaPageParams,
  errorHandle,
  validateFields,
  padLeft,
  formatDate,
  isMobile,
} from '../../util/functions';
import Label from '../../components/Label';
import { ClienteDocumentoModel } from '../../util/Models';
import DropdownLazy from '../../components/DropdownLazy';
import { showMessage } from '../../components/MessageDialog';
import InputTextSp from '../../components/InputTextSp';
import DropdownSp from '../../components/DropdownSp';
import AuthService from '../../services/AuthService';
import ButtonSp from '../../components/ButtonSp';
import { cfgPtBr } from '../../config/Constantes';
import ContabilidadeService from '../../services/ContabilidadeService';
import ClienteService from '../../services/ClienteService';
import CalendarSp from '../../components/CalendarSp';
import history from '../../services/history';

export default function ClienteDocumento(props) {
  // useMemo
  const pageParams = useMemo(
    () => buscaPageParams(props.match.params, props.location.search),
    [props.location.search, props.match.params]
  );
  const queryParams = queryString.parse(props.location.search);
  const idContabilidadeParam = queryParams.idContabilidade;
  const idClienteParam = queryParams.idCliente;
  const idDocumentoTipoParam = queryParams.idDocumentoTipo;

  const filterService = useMemo(() => ClienteDocumentoService.getFilter(), []);
  const usuarioLogado = useMemo(() => AuthService.getUsuario(), []);

  // useStates
  const { backTo } = pageParams;
  filterService.idDocumentoTipo = idDocumentoTipoParam;
  const [filter, setFilter] = useState(filterService);
  const [clienteDocumentos, setClienteDocumentos] = useState([]);
  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [clienteDocumento, setClienteDocumento] = useState(new ClienteDocumentoModel());

  const [contabilidadePesquisaSelecionada, setContabilidadePesquisaSelecionada] = useState();

  const [clientePesquisaSelecionado, setClientePesquisaSelecionado] = useState();
  const [documentoSelecionado, setDocumentoSelecionado] = useState();

  const [listaTipoDocumentos, setListaTipoDocumentos] = useState([]);
  const [cadastroVisivel, setCadastroVisivel] = useState();
  const [statusTela, setStatusTela] = useState(pageParams.statusTela);
  const [fileName, setFileName] = useState();
  const [extensaoArquivo, setExtensaoArquivo] = useState();
  const [bloquearCliente, setBloquearCliente] = useState(false);
  const [bloquearContabilidade, setBloquearContabilidade] = useState(false);

  // funcoes
  const handleBuscar = useCallback(async (_filter, _page) => {
    _filter.page = _page;
    try {
      const result = await ClienteDocumentoService.findAll(_filter);
      setClienteDocumentos(result.items);
      setDocumentoSelecionado(null);
      setPageLimit(result.limit);
      setTotalRecords(result.totalRecords);
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  const carregaContabilidade = useCallback(async _nome => {
    if (_nome !== '') {
      const r = await ContabilidadeService.findAll({ nome: _nome });
      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const carregaCliente = useCallback(async _razaoSocial => {
    if (_razaoSocial !== '') {
      const r = await ClienteService.findAll({ razaoSocial: _razaoSocial });
      const retorno = r.items.map(e => {
        return {
          label: e.razaoSocial,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const carregaTipoDocumento = useCallback(async () => {
    const r = await DocumentoTipoService.findAll({ sinteticoAnalitico: 'A' });
    const retorno = r.items.map(e => {
      return {
        label: e.nome,
        value: e.id,
        extensaoArquivo: e.extensaoArquivo,
      };
    });
    setListaTipoDocumentos(retorno);
  }, []);

  function onPage(event) {
    const pagina = event.first / event.rows;
    setFirst(event.first);
    handleBuscar(filter, pagina);
  }

  // useCallbacks
  const excluirRegistro = useCallback(
    async _id => {
      try {
        await ClienteDocumentoService.delete(_id);
        toast.success('Registro excluído com sucesso.');
        handleBuscar(filter);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filter, handleBuscar]
  );

  const confirmaExclusao = useCallback(
    idSelecionado => {
      showMessage('Confirmação', 'Confirma a exclusão do registro?', idx => {
        if (idx === 1) {
          excluirRegistro(idSelecionado);
        }
      });
    },
    [excluirRegistro]
  );

  function handleSalvar() {
    showMessage('Confirmação', 'Confirma os dados?', idx => {
      if (idx === 1) {
        salvarRegistro();
        handleBuscar(filter);
      }
    });
  }

  async function downloadFile(uri) {
    showMessage('Confirmação', 'Confirma o download?', async idx => {
      if (idx === 1) {
        const name = path.basename(uri);
        let file;
        try {
          file = await ClienteDocumentoService.downloadDocumento(uri);
        } catch (err) {
          errorHandle(err);
        }
        if (file) {
          const blob = new Blob([file]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click();
        }
      }
    });
  }

  function handleNovoRegistro() {
    setClienteDocumento(new ClienteDocumentoModel());
    setStatusTela(StatusTela.stInserir);
    setCadastroVisivel(true);
  }

  async function handleAlterarRegistro() {
    const retorno = await ClienteDocumentoService.findById(documentoSelecionado.id);
    setStatusTela(StatusTela.stAlterar);
    retorno.mesReferencia = new Date(retorno.mesReferencia);
    setClienteDocumento(retorno);
    const extArqui = retorno.documentoTipo ? retorno.documentoTipo?.extensaoArquivo : '*';
    setExtensaoArquivo(extArqui);
    setFileName('');
    setCadastroVisivel(true);
  }

  async function salvarRegistro() {
    if (clienteDocumento.file) {
      if (clienteDocumento.file.size > 10000001) {
        toast.warn('Arquivo nao pode ter tamanho superior a 10M');
        return;
      }
    }

    try {
      clienteDocumento.mesReferencia = new Date(
        `${formatDate(clienteDocumento.mesReferencia, 'yyyy-MM')}-01 13:00`
      );
      if (statusTela === StatusTela.stInserir) {
        clienteDocumento.idCliente = filter.idCliente;
        clienteDocumento.idContabilidade = filter.idContabilidade;
        clienteDocumento.dataLancamento = new Date();
        await ClienteDocumentoService.insert(clienteDocumento);
      } else {
        await ClienteDocumentoService.update(clienteDocumento);
      }

      toast.success('Registro salvo com sucesso.');
      setCadastroVisivel(false);
      handleBuscar(filter);
    } catch (err) {
      errorHandle(err);
    }
  }

  async function carregaPadroes() {
    let contabilidadePadrao;
    if (usuarioLogado.contabilidade) {
      contabilidadePadrao = usuarioLogado.contabilidade;
    } else if (idContabilidadeParam) {
      contabilidadePadrao = await ContabilidadeService.findById(idContabilidadeParam);
    }

    const contabilidadeDropdow = contabilidadePadrao
      ? { label: contabilidadePadrao.nome, value: contabilidadePadrao.id }
      : null;
    let clientePadrao;
    if (usuarioLogado.cliente) {
      clientePadrao = usuarioLogado.cliente;
    } else if (idClienteParam) {
      clientePadrao = await ClienteService.findById(idClienteParam);
    }

    const clienteDropdow = clientePadrao
      ? { label: clientePadrao.razaoSocial, value: clientePadrao.id }
      : null;
    const idCliente = clientePadrao ? clientePadrao.id : 0;
    const idContabilidade = contabilidadePadrao ? contabilidadePadrao.id : 0;
    const novoFilter = { ...filter, idCliente, idContabilidade };
    setBloquearCliente(idCliente !== 0);
    setBloquearContabilidade(idCliente !== 0);
    setFilter(novoFilter);
    setClientePesquisaSelecionado(clienteDropdow);
    setContabilidadePesquisaSelecionada(contabilidadeDropdow);
    carregaTipoDocumento();
    handleBuscar(novoFilter, 0);
  }

  // useEffects
  useEffect(() => {
    carregaPadroes();

    // desativado para evitar que a cada vez que o usuario digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // renders
  return (
    <Container className="container-page">
      <div className="p-grid">
        <BarraTitulo
          title="Documentos"
          showBtnBack={backTo}
          handleBack={() => {
            history.push(backTo);
          }}
        />
        {renderPesquisa()}
        {renderCadastro()}
      </div>
    </Container>
  );

  function renderPesquisa() {
    return (
      <>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleBuscar(filter);
          }}
        >
          <PainelFiltro className="p-grid p-col-12" visible="true">
            <div className="p-grid p-col-12 p-sm-3 p-lg-3" style={{ padding: 0, margin: 0 }}>
              <div className="p-col-6 p-sm-6 p-lg-6 p-fluid">
                <Label htmlFor="dataInicial">De: </Label>
                <CalendarSp
                  readOnlyInput
                  view="month"
                  required
                  appendTo={document.body}
                  dateFormat="mm/yy"
                  yearNavigator
                  locale={cfgPtBr}
                  id="dataInicial"
                  value={filter.dataInicial}
                  yearRange="2010:2040"
                  onChange={e => setFilter({ ...filter, dataInicial: e.value })}
                />
              </div>
              <div className="p-col-6 p-sm-6 p-lg-6 p-fluid">
                <Label htmlFor="dataFinal">Até: </Label>
                <CalendarSp
                  required
                  appendTo={document.body}
                  readOnlyInput
                  locale={cfgPtBr}
                  id="dataFinal"
                  view="month"
                  dateFormat="mm/yy"
                  value={filter.dataFinal}
                  yearNavigator
                  yearRange="2010:2040"
                  onChange={e => setFilter({ ...filter, dataFinal: e.value })}
                />
              </div>
            </div>

            <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
              <Label>Contabilidade</Label>
              <DropdownLazy
                required
                disabled={AuthService.getUsuario().tipoUsuario !== 0 || bloquearContabilidade}
                placeholder="Selecione"
                onChange={e => {
                  setFilter({ ...filter, idContabilidade: e?.value });
                  setContabilidadePesquisaSelecionada(e);
                  handleBuscar({ ...filter, idContabilidade: e?.value });
                }}
                value={contabilidadePesquisaSelecionada}
                onFilter={async txtFilter => {
                  const retorno = await carregaContabilidade(txtFilter);
                  return retorno;
                }}
              />
            </div>

            <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
              <Label>Cliente</Label>
              <DropdownLazy
                required
                disabled={AuthService.getUsuario().tipoUsuario === 2 || bloquearCliente}
                placeholder="Selecione"
                onChange={e => {
                  setFilter({ ...filter, idCliente: e?.value });
                  setClientePesquisaSelecionado(e);
                  handleBuscar({ ...filter, idCliente: e?.value });
                }}
                value={clientePesquisaSelecionado}
                onFilter={async txtFilter => {
                  const retorno = await carregaCliente(txtFilter);
                  return retorno;
                }}
              />
            </div>

            <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
              <Label>Tp Doc.</Label>
              <DropdownSp
                showClear
                filter
                options={listaTipoDocumentos}
                placeholder="Todos os Tipos"
                onChange={e => {
                  setFilter({ ...filter, idDocumentoTipo: e?.value });
                  handleBuscar({ ...filter, idDocumentoTipo: e?.value });
                }}
                value={filter.idDocumentoTipo}
              />
            </div>
            <div className="p-col-12 p-lg-12" style={{ textAlign: 'end' }}>
              <ButtonSp
                label="Inserir"
                icon="pi pi-file"
                onClick={() => handleNovoRegistro()}
                type="button"
                mode="basic"
                disabled={!filter.idCliente || !filter.idContabilidade}
              />
              <ButtonSp
                label="Alterar"
                className="p-button-success buttons"
                icon="pi pi-pencil"
                type="button"
                onClick={() => handleAlterarRegistro()}
                disabled={
                  !documentoSelecionado || !filter.idCliente || !filter.idContabilidade
                }
              />
              <ButtonSp
                label="Excluir"
                className="p-button-danger buttons"
                icon="pi pi-trash"
                type="button"
                onClick={() => confirmaExclusao(documentoSelecionado.id)}
                disabled={!documentoSelecionado}
              />
              &#160;&#160;&#160;
              <ButtonSp
                className="p-button-secondary buttons"
                icon="pi pi-search"
                title="Buscar"
                type="submit"
                label={isMobile() ? '' : 'Buscar'}
              />
            </div>
          </PainelFiltro>
        </form>
        <div className="p-col-12 p-fluid">
          <DataTable
            selection={documentoSelecionado}
            onSelectionChange={e => setDocumentoSelecionado(e.value)}
            selectionMode="single"
            value={clienteDocumentos}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            lazy
            responsive
            totalRecords={totalRecords}
            first={first}
            onPage={onPage}
          >
            <Column
              field="id"
              body={rowData => padLeft(rowData.id, 6)}
              header="Id"
              className="grid-col-id"
            />
            <Column
              field="id"
              header="Tipo Doc."
              className="grid-col"
              style={{ width: 200 }}
              body={rowData => rowData.documentoTipo.nome}
            />
            <Column
              field="mesReferencia"
              className="grid-col-data"
              header="Mês Ref."
              body={rowData => formatDate(rowData.mesReferencia, 'MM/yyyy')}
            />
            <Column field="descricao" className="grid-col" header="Descricao" />
            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTable>
        </div>
      </>
    );
  }

  function renderButtonOp(rowData) {
    return (
      <ButtonSp
        className="botao-pequeno p-button-secondary buttons"
        onClick={() => downloadFile(`/files${rowData.fileUri}`)}
        icon="pi pi-download"
      />
    );
  }

  function renderCadastro() {
    function renderFooter() {
      return (
        <div>
          <ButtonSp
            label="Confirmar"
            icon="pi pi-check"
            disabled={
              !validateFields(clienteDocumento, [
                'mesReferencia',
                'descricao',
                'idDocumentoTipo',
                statusTela === StatusTela.stInserir ? 'file' : '',
              ])
            }
            onClick={() => {
              handleSalvar();
            }}
          />
          <ButtonSp
            label="Cancelar"
            icon="pi pi-times"
            onClick={() => setCadastroVisivel(false)}
            className="p-button-secondary"
          />
        </div>
      );
    }

    return (
      <Dialog
        closable={false}
        header={statusTela === StatusTela.stInserir ? 'Cadastrando' : 'Alterando'}
        footer={renderFooter()}
        visible={cadastroVisivel}
        style={{ minWidth: '350px', maxWidth: '600px', width: '95%' }}
        modal
        onHide={() => {
          setCadastroVisivel(false);
        }}
      >
        <div className="p-grid">
          <div className="p-col-6 p-sm-6 p-lg-6 p-fluid">
            <Label>Tipo Doc.</Label>
            <DropdownSp
              required
              filter
              options={listaTipoDocumentos}
              placeholder="Selecione"
              onChange={e => {
                const listDoc = listaTipoDocumentos.filter(d => d.value === e.value);
                setExtensaoArquivo(listDoc.length > 0 ? listDoc[0].extensaoArquivo : '*');
                setClienteDocumento({
                  ...clienteDocumento,
                  file: null,
                  idDocumentoTipo: e?.value,
                });
                setFileName('');
              }}
              value={clienteDocumento.idDocumentoTipo}
            />
          </div>
          <div className="p-col-6 p-sm-6 p-lg-6 p-fluid">
            <Label>Mês Ref.</Label>
            <CalendarSp
              required
              readOnlyInput
              locale={cfgPtBr}
              view="month"
              dateFormat="mm/yy"
              value={clienteDocumento.mesReferencia}
              yearNavigator
              yearRange="2010:2040"
              onChange={e => {
                setClienteDocumento({ ...clienteDocumento, mesReferencia: e.value });
              }}
            />
          </div>
          <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
            <Label>Descrição</Label>
            <InputTextSp
              value={clienteDocumento.descricao}
              maxLength={100}
              required
              onChange={e => {
                setClienteDocumento({ ...clienteDocumento, descricao: e?.target.value });
              }}
            />
          </div>
          <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
            <InputTextSp
              type="file"
              required={statusTela === StatusTela.stInserir}
              accept={extensaoArquivo ? `.${extensaoArquivo}` : '*'}
              value={fileName}
              disabled={!clienteDocumento.idDocumentoTipo}
              onChange={e => {
                const file = e.target.files && e.target.files[0] ? e.target.files[0] : null;
                setFileName(e.target.value);
                setClienteDocumento({ ...clienteDocumento, file });
              }}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}
